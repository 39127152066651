import React from "react";
import SurveyComponent from "./survey";
import "../css/ranking.css";
import "../css/index.css";

function Ranking() {
  return (
    <div className="ranking-container">
     <SurveyComponent />
    </div>
  );
}

export default Ranking;
